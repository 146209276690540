<template>
  <div class="add-type-container">
    <div class="add-nav">
      <svg-icon icon-class="input"></svg-icon>
      <div class="title">
        手动发布
      </div>
      <div class="desc">
        通过KubeStar界面填写相关信息进行发布，适合首次部署到Kubernetes的应用，更加快速便捷
      </div>
    </div>
    
    <router-link class="add-nav" to="/add/import">
      <svg-icon icon-class="import"></svg-icon>

      <div class="title">
        导入YAML
      </div>
      <div class="desc">
        通过导入现有Kubernetes yaml配置进行发布，适合曾部署过Kubernetes的应用，无需额外修改
      </div>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.add-type-container {
  @include flex(center, flex-start, nowrap);
  height: calc(100vh - 90px);
  text-align: center;

  .add-nav {
    width: 240px;
    padding: 160px 20px 0;
    cursor: pointer;
    box-sizing: border-box;

    .svg-icon {
      width: 40px;
      height: 40px;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      margin: 10px 0;
    }

    .desc {
      line-height: 1.5;
    }

    &:hover {
      .title,
      .desc {
        color: $color-primary;
      }
    }
  }
}
</style>
